import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
// eslint-disable-next-line
import { TextField, Button, Box, Card, CardContent } from '@mui/material';
import styled from '@emotion/styled';

const IframeContainer = styled(Box)`
  margin: 0 auto;
  max-width: 560px;
  text-align: center;
`;

const EmbedVideo = () => {
  const { videoId: paramVideoId } = useParams('');
  const videoId = paramVideoId || 'KuTd67JVZtM';
  const [videoUrl, setVideoUrl] = useState('');
  const [inputValue, setInputValue] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (videoId) {
      setVideoUrl(`https://www.youtube.com/embed/${videoId}`);
    } else {
      const path = new URLSearchParams(location.search).get('path');
      if (path && path.startsWith('/video/')) {
        const id = path.split('/video/')[1];
        setVideoUrl(`https://www.youtube.com/embed/${id}`);
        navigate(path, { replace: true });
      }
    }
  }, [videoId, location.search, navigate]);

  const extractVideoId = (url) => {
    const embedUrlRegex = /embed\/([a-zA-Z0-9_-]{11})/;
    const shortUrlRegex = /youtu\.be\/([a-zA-Z0-9_-]{11})/;
    const standardUrlRegex = /v=([a-zA-Z0-9_-]{11})/;
    const match = url.match(embedUrlRegex) || url.match(shortUrlRegex) || url.match(standardUrlRegex);
    return match ? match[1] : null;
  };

  // eslint-disable-next-line
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  // eslint-disable-next-line
  const handleFormSubmit = (event) => {
    event.preventDefault();
    const videoId = extractVideoId(inputValue);
    if (videoId) {
      setVideoUrl(`https://www.youtube.com/embed/${videoId}`);
      setInputValue('');
      navigate(`/video/${videoId}`);
    } else {
      alert('Invalid YouTube URL');
    }
  };

  return (
    <Card className="my-8">
      <CardContent>
        <IframeContainer>
          {videoUrl && (
            <iframe
              width="100%"
              height="315"
              src={videoUrl}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embed Video"
              className="rounded-lg"
            ></iframe>
          )}
        </IframeContainer>
      </CardContent>
    </Card>
  );
};

export default EmbedVideo;
