import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import EmbedVideo from './components/EmbedVideo';
import CountdownTimer from './components/CountdownTimer';
import './index.css'; // Ensure Tailwind CSS is imported

const App = () => (
  <Router>
    <div className="bg-gray-100 min-h-screen">
      <header className="bg-gray-800 text-white py-3">
        <a href="https://StudyWithMe.HoHieu.com" className="text-3xl text-center block">
          <h1>Study With Me</h1>
        </a>
      </header>
      <div className="container mx-auto p-4">
        <Routes>
          <Route path="/" element={<PageWithContent />} />
          <Route path="/video/:videoId" element={<PageWithContent />} />
        </Routes>
      </div>
    </div>
  </Router>
);

const PageWithContent = () => (
  <>
    <EmbedVideo />
    <CountdownTimer />
  </>
);



export default App;

